import React from 'react'
import { Link } from 'react-router-dom';
import CardHeader from './CardHeader';

const Card = () => {
    const images = [
       
        {
          imageUrl: "/Images/54.jpg",
          imageUrl2:"/Images/55.jpg",
          title: 'Rani Pink Lehenga Set',
          price:"20,625"
        },
        {
          imageUrl: 'Images/4.jpg',
                    title: 'brocade chanderi anarkali set',
                    price:"20,250"
        },
        {
          imageUrl: "/Images/39.jpg",
                    title: 'Chocolate Organza saree set',
                    price:"17,625"
        },
       
        {
          imageUrl: 'Images/13.jpg',
                    title: "chanderi jhali gharara set",
                    price:"18,000"
        }
       
       
        // Add more image objects as needed
      ];

    
      
        
    
  return (
    <div>
        <CardHeader/>
        <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mt-8 px-6'>
        {images.map((image, index) => (
          <div key={index} className='p-4 '>
            {/* <div className='object-cover w-full h-auto shadow-md mb-4 relative'> */}
              
            <img
              src={image.imageUrl}
              alt={image.title}
              className='object-cover w-full h-auto shadow-md mb-4 transition-transform duration-300 transform hover:scale-110'
            />
             {/* <img
              src={image.imageUrl2}
              alt={image.title}
              // className='object-cover w-full h-auto shadow-md mb-4 transition-transform duration-300 transform hover:scale-110 '
            /> */}
            {/* </div> */}
            <h5 className='text-xs md:text-sm my-8 text-black-500 uppercase text-center uppercase font-small mb-2 tracking-wide'>{image.title}</h5>
            {/* <p className='text-center text-xs'>Rs. {image.price}</p> */}
            
            {/* Add additional content for each card if needed */}
            {/* For example, you can add a button or description */}
            {/* <p className='text-sm'>Some description here...</p> */}
          </div>
        ))}
      </div>
     
    </div>
  )
}

export default Card